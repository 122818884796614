import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Module({ data, isAssociated }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (menuOpen && menuRef.current && buttonRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const overflowBottom = menuRect.bottom > window.innerHeight;
      const overflowTop = buttonRect.top > menuRect.height;
      setOpenUpwards(overflowBottom && overflowTop);
    }
  }, [menuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <tr onClick={() => navigate(`/hotels/${data.hotel_id}/modules/${data.module_id}`)} className="cursor-pointer hover:bg-gray-50 relative">
      <td className="px-6 py-4 whitespace-nowrap">{data.devEUI}</td>
      <td className="px-6 py-4 whitespace-nowrap">{data.version}</td>
      <td className="px-6 py-4 whitespace-nowrap text-right relative">
        <button ref={buttonRef} onClick={handleMenuClick} className="text-gray-500 hover:text-gray-700">
          •••
        </button>
        {menuOpen && (
          <div ref={menuRef} className={`absolute ${openUpwards ? "bottom-full mb-2" : "mt-2"} right-0 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10`}>
            <ul>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  if (isAssociated) {
                    console.log(`Désassocier module de la chambre id : ${data.id}`);
                  } else {
                    console.log(`Associer module à la chambre`);
                  }
                }}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-green-600"
              >
                {isAssociated ? "Désassocier de la chambre" : "Associer à une chambre"}
              </li>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(`Supprimer module id : ${data.id}`);
                }}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600"
              >
                Supprimer module
              </li>
            </ul>
          </div>
        )}
      </td>
    </tr>
  );
}
