import React from "react";
import { useNavigate } from "react-router-dom";

export function Heartbeat({ data }) {
  const navigate = useNavigate();

  return (
    <tr className="cursor-pointer hover:bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap">{data.created_at}</td>
      <td className="px-6 py-4 whitespace-nowrap">{data.batteryLvl}</td>
    </tr>
  );
}
