import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EditRoomModal } from "./EditRoomModal";
import { AssociateModuleModal } from "./AssociateModuleModal";
import { useAuth } from "react-oidc-context";

export function Room({ data, isAssociated }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModifModalOpen, setIsModifModalOpen] = useState(false);
  const [isAssociateModalOpen, setIsAssociateModalOpen] = useState(false);
  const menuRef = useRef(null);

  const roomId = data.room_id || data.id;
  const hotelId = data.hotel_id;

  const auth = useAuth();
  // console.log(auth);
  const token = auth.user.access_token;
  // console.log(token);
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  function toggleModifModal() {
    setIsModifModalOpen(!isModifModalOpen);
  }

  function toggleAssociateModal() {
    setIsAssociateModalOpen(!isAssociateModalOpen);
  }

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const dissociateRoomModule = (e) => {
    e.stopPropagation();

    axios
      .put(
        `${API_URL}/hotels/${hotelId}/rooms/${roomId}/dissociate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        navigate(0);
      })
      .catch((err) => {
        console.error("Erreur dissocier module:", err);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <tr
        onClick={() => {
          navigate(`/hotels/${hotelId}/rooms/${roomId}`, {
            state: { hotel_id: hotelId, id: roomId },
          });
        }}
        className="cursor-pointer hover:bg-gray-50 relative"
      >
        <td className="px-6 py-4 whitespace-nowrap">{data.room_nb}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.nb_places}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.has_module ? "Oui" : "Non"}</td>
        <td className="px-6 py-4 whitespace-nowrap text-right relative">
          <button onClick={handleMenuClick} className="text-gray-500 hover:text-gray-700">
            •••
          </button>
          {menuOpen && (
            <div ref={menuRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
              <ul>
                {isAssociated ? (
                  <li onClick={dissociateRoomModule} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    Désassocier Module
                  </li>
                ) : (
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleAssociateModal();
                    }}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    Associer Module
                  </li>
                )}

                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleModifModal();
                  }}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-blue-600"
                >
                  Modifier chambre
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(`Supprimer chambre id : ${roomId}`);
                  }}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600"
                >
                  Supprimer chambre
                </li>
              </ul>
            </div>
          )}
        </td>
      </tr>
      <EditRoomModal isOpen={isModifModalOpen} onClose={toggleModifModal} room={data} hotelId={hotelId} />
      <AssociateModuleModal isOpen={isAssociateModalOpen} onClose={toggleAssociateModal} hotelId={hotelId} roomId={roomId} />
    </>
  );
}
