import React, { useState } from "react";

export function Carrousel({ hotel, chambre }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;

  const getLuniShowerInfos = (info) => {
    switch (info) {
      case 0:
        return "Pas équipée";
      case 1:
        return "Equipée";
      default:
        return "Inconnu";
    }
  };

  const getPmrInfos = (info) => {
    switch (info) {
      case 0:
        return "Non";
      case 1:
        return "Oui";
      default:
        return "Inconnu";
    }
  };

  const roomData =
    chambre && hotel
      ? [
          { title: "Nom de l'hôtel", content: hotel.name },
          { title: "Adresse de l'hôtel", content: hotel.location },
          { title: "Numéro de la chambre", content: chambre.room_nb },
          { title: "Etage", content: chambre.floor_nb },
          { title: "Nombre de lit", content: chambre.nb_places },
          { title: "Module", content: getLuniShowerInfos(chambre.is_lunishower) },
          { title: "Aménagement PMR", content: getPmrInfos(chambre.is_pmr) },
        ]
      : [];

  const totalItems = roomData.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + Math.max(totalItems - itemsToShow + 1, 1)) % (totalItems - itemsToShow + 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.max(totalItems - itemsToShow + 1, 1));
  };

  return (
    <div className="relative overflow-hidden">
      <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
        {roomData.map((item, index) => (
          <div key={index} className="flex-shrink-0 w-full lg:w-1/3 xl:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg border border-gray-200 hover:border-gray-300 transition duration-300">
              <div className="relative">
                <div className="absolute inset-x-0 top-0 bg-[#98b77f] py-2 px-4 rounded-t-lg text-lg font-semibold text-[#365644] text-center">{item.title}</div>
                <div className="pt-12 p-6">
                  <p className="text-gray-800 mt-5 text-center bold">{item.content}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handlePrev} className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full shadow-md hover:bg-gray-700" aria-label="Previous">
        &lt;
      </button>
      <button onClick={handleNext} className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full shadow-md hover:bg-gray-700" aria-label="Next">
        &gt;
      </button>
    </div>
  );
}
