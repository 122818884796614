import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import luniwaveLogo from "../assets/logo_rond.png";
import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Carrousel } from "../components/Room/Carroussel_Room";
import { Shower } from "../components/Room/Shower";
import { Module } from "../components/Module/Module";
import { Heartbeat } from "../components/Heartbeat/Heartbeat";
import { EditRoomModal } from "../components/Room/EditRoomModal";

function RoomPage() {
  const { hotel_id, id } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listeModules, setListeModules] = useState();
  const [listeShowers, setListeShowers] = useState();
  const [listeHeartbeats, setListeHeartbeats] = useState();
  const [chambre, setChambre] = useState();
  const [hotel, setHotel] = useState();
  const [activeFilter, setActiveFilter] = useState("modules");
  const [formData, setFormData] = useState({
    room_nb: "",
    is_lunishower: 1,
    is_pmr: 0,
    nb_places: 0,
    floor_id: 0,
  });

  const auth = useAuth();
  const token = auth.user.access_token;
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseChambres = await axios.get(`${API_URL}/hotels/${hotel_id}/rooms/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(responseChambres.data.result.room);
        setChambre(responseChambres.data.result.room);
        setListeModules(responseChambres.data.result.modules);

        const responseShowers = await axios.get(`${API_URL}/hotels/${hotel_id}/rooms/${id}/showers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setListeShowers(responseShowers.data);

        axios
          .get(`${API_URL}/hotels/${hotel_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const resultHotel = response.data.result;
            setHotel(resultHotel);
          });

        axios
          .get(`${API_URL}/hotels/${hotel_id}/rooms/${id}/heartbeats`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const resultHeartbeats = response.data.result;
            setListeHeartbeats(resultHeartbeats);
          });
      } catch (error) {
        console.error("Error fetching shower data:", error);
      }
    };

    fetchData();
  }, [hotel_id, id]);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const handleSubmit = () => {
    if (!formData.room_nb) {
      alert("Veuillez entrer un numéro de chambre.");
      return;
    }

    axios
      .patch(`${API_URL}/hotels/${hotel_id}/rooms/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate(0);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error("Error updating hotel:", err);
      });
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(`/hotels/${hotel_id}`)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          {chambre && <h1 className="text-2xl font-bold">Détail de la chambre : {chambre.room_nb} </h1>}
          <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3" onClick={toggleModal} style={{ marginLeft: "auto" }}>
            Modifier les informations de la chambre
          </button>
        </div>

        {hotel && chambre && <Carrousel hotel={hotel} chambre={chambre} className="mt-4" />}

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant la chambre</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">Description : {hotel?.content ? hotel?.content : "Pas de description"}</span>
        </div>

        {/* Boutons de filtre pour les sections "Modules installés" et "Liste des douches" */}
        <div className="mt-5 flex justify-around">
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "modules" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("modules")}>
            Modules installés
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "heartbeats" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("heartbeats")}>
            Liste des heartbeats
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "showers" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={() => handleFilterClick("showers")}>
            Liste des douches
          </button>
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full" />

        {/* Contenu affiché en fonction du filtre actif */}
        {activeFilter === "modules" && (
          <div className="mb-4 mt-4">
            <h2 className="font-bold text-lg mb-2">Modules installés dans la chambre</h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">DevEUI</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Version</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">{listeModules?.length === 0 ? <div>Aucun module installé dans la chambre.</div> : listeModules?.map((module, index) => <Module key={index} data={module} isAssociated={true} />)}</tbody>
              </table>
            </div>
          </div>
        )}

        {activeFilter === "heartbeats" && (
          <div className="mb-4 mt-4">
            <h2 className="font-bold text-lg mb-2">Liste des heartbeats</h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Niveau de batterie</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">{listeHeartbeats && listeHeartbeats.length === 0 ? <div>Aucune douche disponible pour le moment.</div> : listeHeartbeats && listeHeartbeats.map((heartbeat, index) => <Heartbeat key={index} data={heartbeat} />)}</tbody>
              </table>
            </div>
          </div>
        )}

        {activeFilter === "showers" && (
          <div className="mb-4 mt-4">
            <h2 className="font-bold text-lg mb-2">Liste des douches</h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Date de la douche</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Température (°C)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Volume (par L)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Objectif fixé (par L)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Intéraction effectuée</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">{listeShowers && listeShowers.result.length === 0 ? <div>Aucune douche disponible pour le moment.</div> : listeShowers && listeShowers.result.map((shower, index) => <Shower key={index} data={shower} />)}</tbody>
              </table>
            </div>
          </div>
        )}

        {isModalOpen && <EditRoomModal isOpen={isModalOpen} onClose={toggleModal} room={chambre} hotelId={hotel_id} />}
      </div>
    </div>
  );
}

export default RoomPage;
