import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

export function AssociateModuleModal({ isOpen, onClose, hotelId, roomId }) {
  const navigate = useNavigate();

  const [listeModules, setListeModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  const auth = useAuth();
  const token = auth.user.access_token;
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${API_URL}/hotels/${hotelId}/modules`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Filtrer les modules sans chambre associée
          const modulesSansChambre = response.data.result.filter((module) => !module.room_id);
          setListeModules(modulesSansChambre);
        })
        .catch((error) => {
          console.error("Error fetching modules:", error);
        });
    }
  }, [isOpen, hotelId]);

  const handleSubmit = () => {
    if (!selectedModule) {
      alert("Veuillez sélectionner un module.");
      return;
    }
    console.log(selectedModule);
    console.log(listeModules);
    const moduleId = listeModules[selectedModule].module_id;
    console.log(moduleId);
    axios
      .put(
        `${API_URL}/hotels/${hotelId}/rooms/${roomId}/associate/${moduleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Module associé avec succès :", response.data);
        navigate(0);
      })
      .catch((err) => {
        console.error("Erreur lors de l'association du module :", err);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
        <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Associer un Module à la Chambre</h2>
        <button type="button" className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" onClick={onClose} aria-label="Close">
          &times;
        </button>
        <div className="flex flex-col mb-4">
          <label className="mb-1 font-medium">Sélectionnez un module</label>
          <select value={selectedModule || ""} onChange={(e) => setSelectedModule(e.target.value)} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black">
            <option value="">-- Sélectionnez un module --</option>
            {listeModules.map((module, index) => (
              <option key={module.id} value={index}>
                {module.devEUI} (Version: {module.version})
              </option>
            ))}
          </select>
        </div>
        <button onClick={handleSubmit} className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200">
          Associer le module
        </button>
      </div>
    </div>
  );
}
