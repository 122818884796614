import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

function Home() {
  const [generalHotel, setGeneralHotel] = useState([]);
  const [invisibleHotel, setInvisibleHotel] = useState([]);
  const [alertsHotel, setAlertsHotel] = useState([]);
  const [activeFilter, setActiveFilter] = useState("general");

  const auth = useAuth();
  const token = auth.user.access_token;
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/checkVisibleHotels`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGeneralHotel(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching general hotels:", error);
      });
  }, [API_URL, token]);

  const handleInvisibleHotel = () => {
    axios
      .get(`${API_URL}/checkInvisibleHotels`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setInvisibleHotel(response.data.result);
        setActiveFilter("is_visible");
      })
      .catch((error) => {
        console.error("Error fetching visible hotels:", error);
      });
  };

  const handleAlertsHotel = () => {
    axios
      .get(`${API_URL}/checkHotelsAlerts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAlertsHotel(response.data.result);
        setActiveFilter("alertes");
      })
      .catch((error) => {
        console.error("Error fetching hotels with alerts:", error);
      });
  };

  const handleGeneralClick = () => {
    setActiveFilter("general");
  };

  const filteredHotels = activeFilter === "general" ? generalHotel || [] : activeFilter === "is_visible" ? invisibleHotel || [] : activeFilter === "alertes" ? alertsHotel || [] : [];

  const handleHotelsRedirection = (id) => {
    navigate(`/hotels/${id}`);
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        <div className="mb-8 mt-8">
          <h1 className="mb-4 text-2xl ml-6 font-semibold">Liste des hôtels</h1>
        </div>

        <div className="flex w-full justify-around">
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "general" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={handleGeneralClick}>
            En général
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "alertes" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={handleAlertsHotel}>
            En Alerte
          </button>
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "is_visible" ? "bg-[#BEDDAB]" : "bg-gray-50"}`} onClick={handleInvisibleHotel}>
            En invisible
          </button>
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full" />

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {filteredHotels && filteredHotels.length === 0 ? (
            <div className="p-6 text-center text-gray-500">Aucun hôtel disponible pour le moment.</div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nom de l'hôtel</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Adresse de l'hôtel</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nombre d'étages</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nombre de chambres</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredHotels.map((hotel) => (
                  <tr key={hotel.id} onClick={() => handleHotelsRedirection(hotel.id)} className="cursor-pointer hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{hotel.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{hotel.location}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{hotel.num_floor}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{hotel.num_rooms}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
