import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import luniwaveLogo from "../assets/logo_rond.png";
import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Carrousel } from "../components/Hotel/Carroussel_Hotel";
import { Shower } from "../components/Room/Shower";
import { Module } from "../components/Module/Module";
import { Room } from "../components/Room/Room";

function ModulePage() {
  const { hotel_id, floor_id, id } = useParams();
  const navigate = useNavigate();

  const [module, setModule] = useState();
  const [room, setRoom] = useState();
  const [hotel, setHotel] = useState();

  const auth = useAuth();
  const token = auth.user.access_token;
  // const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "/api";

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const responseChambres = await axios
          .get(`${API_URL}/hotels/${hotel_id}/modules/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setModule(response.data.result.module);
            setRoom(response.data.result.room);
          });
      } catch (error) {
        console.error("Error fetching shower data:", error);
      }
    };

    axios
      .get(`${API_URL}/hotels/${hotel_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const resultHotel = response.data.result;
        setHotel(resultHotel);
      });

    fetchRoomData();
  }, [hotel_id, id]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(`/hotels/${hotel_id}`)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>

          {module && <h1 className="text-2xl font-bold">Détail du module : {module?.devEUI} </h1>}
        </div>

        {hotel && module && <Carrousel hotel={hotel} chambre={module} className="mt-4" />}

        <div className="mb-4 mt-4">
          <div className="flex justify-between mb-2">
            <h2 className="font-bold text-lg mb-2">Chambre du module</h2>
            {/* <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3" onClick={() => navigate(`/hotels/${id}/complete`)}>
              Compléter cet hôtel
            </button> */}
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Numéro de Chambre</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nombre de Places</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Module équipé</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-center">{room ? <Room data={room} state={hotel} /> : <div>Aucune chambre disponible pour le moment.</div>}</tbody>
            </table>
          </div>
        </div>

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant le module</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">
            Description : {hotel?.content ? hotel?.content : "Pas de description"}
            <br /> DevEUI : {module?.devEUI ? module?.devEUI : "Pas de DevEUI (problème)"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ModulePage;
