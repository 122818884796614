import React, { useState } from "react";

export function Carrousel({ hotel }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;

  const getSubscriptionType = (type) => {
    switch (type) {
      case 0:
        return "Standard";
      case 1:
        return "Premium";
      default:
        return "Inconnu";
    }
  };

  const hotelData = hotel
    ? [
        { title: "Id dans la DB", content: hotel.id },
        { title: "AppId", content: hotel.app_id },
        { title: "Adresse de l'hôtel", content: hotel.location },
        { title: "Nombre d'étages", content: hotel.num_floor },
        { title: "Nombre de chambres", content: hotel.num_rooms },
        { title: "Nombre d'étoiles", content: hotel.stars_number },
        { title: "Type de chauffage", content: hotel.heating_type },
        { title: "Type d'abonnement", content: getSubscriptionType(hotel.subscription_type) },
      ]
    : [];

  const totalItems = hotelData.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + Math.max(totalItems - itemsToShow + 1, 1)) % (totalItems - itemsToShow + 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.max(totalItems - itemsToShow + 1, 1));
  };

  return (
    <div className="relative overflow-hidden">
      <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
        {hotelData.map((item, index) => (
          <div key={index} className="flex-shrink-0 w-full lg:w-1/3 xl:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg border border-gray-200 hover:border-gray-300 transition duration-300">
              <div className="relative">
                <div className="absolute inset-x-0 top-0 bg-[#98b77f] py-2 px-4 rounded-t-lg text-lg font-semibold text-[#365644] text-center">{item.title}</div>
                <div className="pt-12 p-6">
                  <p className="text-gray-800 mt-5 text-center bold">{item.content}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handlePrev} className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full shadow-md hover:bg-gray-700" aria-label="Previous">
        &lt;
      </button>
      <button onClick={handleNext} className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full shadow-md hover:bg-gray-700" aria-label="Next">
        &gt;
      </button>
    </div>
  );
}
